import { isEqualWith } from "lodash";
import { useAppSelector } from "hooks";
import SingleItems from "./lib/SingleItems";
import { useWebNewsContext } from "contexts";
import FeaturedDeals from "./lib/FeaturedDeals";
import { PageContent } from "components/PageContent";
import { IShopSection } from "contexts/WebStoreProvider/types";
import { LimitedDealsSection } from "./lib/LimitedDealsSection";
import { selectShopSectionsState } from "redux/slices/shop-sections";
import PageTopNav, { ISectionMenuItem } from "components/PageTopNav";
import { useState, createRef, useEffect, createContext } from "react";

// eslint-disable-next-line
export const ShopContext = createContext({} as any);

const StoreFront = () => {
	const { resetNewsState } = useWebNewsContext();
	const { shopSections } = useAppSelector(selectShopSectionsState);
	const [sectionMenuData, setSectionMenuData] = useState<ISectionMenuItem[]>(
		[]
	);
	const [mainShopSections, setShopSections] = useState<IShopSection[]>([]);
	useEffect(() => {
		window?.scrollTo(0, 0);
		resetNewsState();
	}, []);

	//Prevent rerendering when processing storeRaw
	useEffect(() => {
		if (!shopSections?.mainShopSections) {
			return;
		}
		if (isEqualWith(shopSections?.mainShopSections, mainShopSections)) {
			return;
		}
		setShopSections(shopSections?.mainShopSections);
	}, [shopSections?.mainShopSections]);

	useEffect(() => {
		setSectionMenuData(
			mainShopSections.map((data) => {
				const { name, title: text, iconImageUrl: icon } = data;
				const newRef = createRef();

				return {
					name,
					text,
					icon,
					sectionRef: newRef,
					analytics_source: "store"
				};
			})
		);
	}, [mainShopSections]);

	return (
		<>
			<ShopContext.Provider value={{ sectionMenuData }}>
				<PageTopNav sectionMenuData={sectionMenuData} />
				<PageContent
					source={"store_page"}
					sxContainer={{ paddingBottom: "0 !important" }}
				>
					<FeaturedDeals />
				</PageContent>
				<LimitedDealsSection />
				<PageContent source={"store_page"} enableBackToTop>
					<SingleItems />
				</PageContent>
			</ShopContext.Provider>
		</>
	);
};

export default StoreFront;
