import styled from "styled-components";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";

export const CustomAccordion = styled((props: AccordionProps) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
	border: "0.5px solid #EBEBEB",
	// "&:not(:last-child)": {
	// 	borderBottom: 0
	// },
	"&:before": {
		height: "0px"
	}
}));

export const Container = styled.div<{ image: string }>`
	position: relative;
	background-image: url(${(props) => props.image});
	background-repeat: no-repeat;
	background-size: 100%;
	//background-size: auto auto;
	width: 100%;
	min-height: 425px;
	display: flex;
	flex-direction: column;
	//justify-content: end;
	//justify-content: center;
`;

export const BgBrush = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	//height: 100%;
`;

export const Title = styled.div`
	color: #113b62;
	z-index: 1;
	margin-top: 55px;
	text-align: center;
	font-family: Baloo;
	font-size: 26px;
	font-style: normal;
	font-weight: 400;
	//line-height: 29px;
`;
