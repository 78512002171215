/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
//import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { BgBrush, Container, CustomAccordion, Title } from "./styles";
import bg from "assets/faq/bg-mobile.png";
import bgBrush from "assets/faq/brush.png";
import { faqData } from "./helpers";

export const FAQ = () => {
	const [expanded, setExpanded] = React.useState<string | false>(false);

	const handleChange =
		(panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
			setExpanded(isExpanded ? panel : false);
		};

	return (
		<Container image={bg} style={{ marginTop: "4rem", borderRadius: "12px" }}>
			<BgBrush src={bgBrush} />
			<Title>{"ANY OPEN QUESTIONS?"}</Title>
			<div style={{ marginTop: "10px" }}>
				{faqData.map((item, index) => (
					<CustomAccordion
						key={index}
						expanded={expanded === `panel${index + 1}`}
						onChange={handleChange(`panel${index + 1}`)}
						sx={{ marginBottom: "8px", borderRadius: "12px" }}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls={`panel${index + 1}bh-content`}
							id={`panel${index + 1}bh-header`}
							sx={{ backgroundColor: "#FBBD05", borderRadius: "12px" }}
						>
							<Typography
								sx={{
									width: "100%",
									flexShrink: 0,
									fontWeight: "700",
									lineHeight: "18px"
								}}
							>
								{item.question}
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography>{item.answer}</Typography>
						</AccordionDetails>
					</CustomAccordion>
				))}
			</div>
		</Container>
	);
};
