export const faqData = [
	{
		question: "What is the TriPeaks Web Shop?",
		answer:
			"The Web Shop is an alternative to the in-app store where you can purchase items for use in game. The most exciting aspects of the Web Shop are the exclusive offers and Featured Deals you’ll get access to. This is where you’ll see the BEST deals on Volcanos, Wilds, Coins, Boosters and anything else you might need to make your time in Tiki Solitaire TriPeaks more enjoyable! Every purchase made through the Web Shop will earn you Loyalty Points that you can redeem even more items in the Loyalty Points section of the Website."
	},
	{
		question: "Is TriPeaks Web shop safe?",
		answer:
			"Yes, the TriPeaks Web Shop is fully secure. We use Xsolla to process all payments, which ensures your transactions are protected by industry-standard SSL (Secure Socket Layer) encryption. This means all your payment details are encrypted and kept safe during transmission. Additionally, Xsolla is PCI DSS compliant, which means they meet the highest security standards for handling credit card information. You can feel confident that your personal and payment data are protected when shopping with us."
	},
	{
		question: "How do I make a purchase?",
		answer:
			"Visit the TriPeaks Web Shop. Select your desired item and click Buy. Enter your payment details at checkout. Confirm your purchase, and your items will appear in your in-game inbox immediately."
	},
	{
		question:
			"Where is my Web Shop purchase & how long does it take to receive it?",
		answer:
			"Your Web Shop purchase will appear in your in-game inbox immediately after the transaction is completed. Simply check your inbox within the game, and you should see your items ready to use right away! If you don’t see it after a few minutes, please refresh the game or contact support."
	},
	{
		question: "What are Loyalty Points?",
		answer:
			"Every purchase made through the Web Shop will earn you Loyalty Points that you can then use to redeem even more items in the Loyalty Points section of the Website. You can also earn Loyalty Points from the Daily Login Reward. You can find the Loyalty Points by clicking the “Loyalty Points” header at the top of the Website. Be sure to check out the Daily Deals section of the Loyalty Shop to take advantage of limited-time offers!"
	}
];
