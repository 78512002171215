/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
import "animate.css";
import {
	Routes,
	Route,
	useLocation,
	useNavigate,
	useSearchParams
} from "react-router-dom";
import { FeaturesFlags, links } from "helpers";
import { Fragment, useEffect, useState } from "react";
import { Toolbar } from "@mui/material";
import AppModalDialogs from "./components/AppModalDialogsV2";
import ImagePreloader from "./components/ImagePreloader";
import PageFooter from "./components/PageFooter";
import AppNavigation from "components/AppNavigation";
import { useEnvContext, useWebStoreContext } from "contexts";
import { useDispatch } from "react-redux";
import { offersApi } from "redux/apis";
import { useDripOffer } from "hooks";
// Pages:
import ClubFreeGift from "pages/ClubFreeGift";
import ClubShop from "pages/ClubShop";
import DailyGift from "pages/DailyGift";
import DripOffer from "pages/DripOffer";
import LandingPage from "pages/LandingPage";
import LoyaltyShop from "pages/LoyaltyShop";
import MerchShop from "pages/MerchShop";
import NewsArticlePage from "pages/News/ArticlePage";
import NewsListPage from "pages/News";
import PageNotFound from "pages/PageNotFound";
import PoisBountyPage from "pages/PoisBounty";
import PrivacyAdsToggle from "pages/PrivacyAdsToggle";
import StoreFront from "pages/StoreFront";
import WinnersPage from "pages/Winners";
import { Test } from "pages/Test";
import { BouncyChest } from "components/BouncyChest";
import StoreFrontV2 from "pages/StoreFront/index_v2";

const MainContent = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { isFeatureFlagEnabled } = useEnvContext();
	const [params, setParams] = useState<{ [key: string]: string }>();
	const { userTracker, immediateLogin, autoLoginExpiredToken, authToken } =
		useWebStoreContext();
	const isFooterEnabled = location.pathname !== "/privacy-ads-toggle";
	const {
		shop,
		loyalty,
		privacy_ads_toggle,
		club_shop,
		free_daily_gifts,
		club_free_gift,
		news,
		pois_bounty,
		winners,
		merch_shop,
		drip_offer
	} = links;

	useDripOffer();

	useEffect(() => {
		const params: { [key: string]: string } = {};
		for (const entry of searchParams.entries()) {
			params[entry[0]] = entry[1];
		}
		setParams(params);
	}, []);

	useEffect(() => {
		if (params) {
			if (
				params.token &&
				params.loginType === "auto" &&
				params.token !== autoLoginExpiredToken
			) {
				immediateLogin(params.token);
			} else {
				navigate(location.pathname);
			}
		}
	}, [params]);

	// Invalidates site offers if clubId changes
	useEffect(() => {
		dispatch(offersApi.util.invalidateTags(["offersTag"]));
	}, [userTracker?.clubId]);

	useEffect(() => {
		if (
			location.pathname === "/daily-gift" ||
			location.pathname === "/daily-free-gift"
		) {
			navigate("/free-daily-gifts");
		}
	}, [location]);

	return (
		<Fragment>
			<Fragment>
				<Toolbar sx={{ minHeight: { xs: 81, sm: 64 } }} />
				<AppNavigation>
					<Routes>
						<Route index element={<LandingPage />} />
						{/* <Route path={shop.path} element={<StoreFront />} /> */}
						<Route
							path={shop.path}
							element={
								//! We must enable the following line when the flag is enabled in feature number 6
								//isFeatureFlagEnabled(FeaturesFlags.SHOP_V2) && authToken?.value ? (
								authToken?.value ? <StoreFrontV2 /> : <StoreFront />
							}
						/>
						<Route path={loyalty.path} element={<LoyaltyShop />} />
						<Route
							path={privacy_ads_toggle.path}
							element={<PrivacyAdsToggle />}
						/>
						<Route path={club_shop.path} element={<ClubShop />} />
						<Route path={free_daily_gifts.path} element={<DailyGift />} />
						<Route path={club_free_gift.path} element={<ClubFreeGift />} />
						<Route path={merch_shop.path} element={<MerchShop />} />
						<Route path={news.path} element={<NewsListPage />} />
						<Route path={`${news.path}/page/:num`} element={<NewsListPage />} />
						<Route path={`${news.path}/:slug`} element={<NewsArticlePage />} />
						<Route path={news.path} element={<NewsListPage />} />
						<Route path={winners.path} element={<WinnersPage />} />
						<Route path={pois_bounty.path} element={<PoisBountyPage />} />
						<Route path={drip_offer.path} element={<DripOffer />} />
						{/* <Route path={"/test"} element={<Test />} /> */}
						<Route path="*" element={<PageNotFound />} />
					</Routes>
				</AppNavigation>
			</Fragment>

			<div
				style={{
					position: "fixed",
					bottom: "0px",
					left: "0px",
					zIndex: 2,
					margin: "18px 12px"
				}}
			>
				{isFeatureFlagEnabled(FeaturesFlags.BOUNCY_CHEST) && <BouncyChest />}
			</div>
			<AppModalDialogs />
			<ImagePreloader />
			{isFooterEnabled && <PageFooter />}
		</Fragment>
	);
};

export default function MainComponent() {
	return <MainContent />;
}
