import styled from "styled-components";

export const VersionInfo = styled.div`
	position: absolute;
	bottom: -5px;
	right: 8px;
	font-size: 11px;
	font-weight: bold;
	font-style: italic;
	color: #7a288a;
	z-index: 999;
`;
