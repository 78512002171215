import { useFacebookContext } from "../../../contexts/FacebookProvider";
import Button from "@mui/material/Button";

import { styled } from "@mui/material";
import { ReactComponent as FacebookIcon } from "../../../assets/FacebookLogo.svg";
import {
	LOCAL_STORAGE_LOGIN_FROM,
	useWebStoreContext
} from "../../../contexts/WebStoreProvider";
import { useGoogleAnalyticsContext } from "../../../contexts/GoogleAnalyticsProvider";
import { LoginType } from "../../../domain/Login/LoginGoogleAnalyticsHelper";
import { ConnectionStatus } from "../../../contexts/FacebookProvider/index";

const FbLoginButton = styled(Button)`
	font-family: "Poppins", sans-serif;
	display: block;
	width: 100%;
	background: transparent;
	border: 2px solid #245fdc;
	color: #245fdc;
	font-size: 0.7rem;
	text-transform: none;
	position: relative;
	line-height: 2.5;
	&:hover {
		background: #245fdc;
		border: 2px solid #245fdc;
		color: white;
	}
`;

const FacebookLoginButton = ({
	onClick,
	hideLogoutState = false,
	source,
	cbClick,
	prevCb,
	style = { marginTop: "0.75rem" }
}: {
	onClick?: () => void;
	cbClick?: () => void;
	prevCb?: () => void;
	hideLogoutState?: boolean;
	source: string;
	style?: React.CSSProperties;
}) => {
	const { isConnected, login: fbLogin } = useFacebookContext();
	const { logout } = useWebStoreContext();
	const { connectionStatus } = useFacebookContext();
	const { loginGoogleAnalyticsHelper } = useGoogleAnalyticsContext();
	return (
		<>
			{!isConnected ? (
				<FbLoginButton
					variant="contained"
					onClick={() => {
						prevCb && prevCb();
						if (onClick) {
							onClick();
						} else {
							const feature = source.split("#").pop();
							if (feature) {
								localStorage.setItem(LOCAL_STORAGE_LOGIN_FROM, source);
							}
							fbLogin();
						}
						loginGoogleAnalyticsHelper.reportLoginOptionClick(
							LoginType.Facebook,
							source
						);
						// Post-click callbacks
						cbClick && cbClick();
					}}
					style={style}
					disabled={connectionStatus !== ConnectionStatus.UNKNOWN}
				>
					<FacebookIcon
						style={{
							width: 17,
							height: "auto",
							position: "relative",
							bottom: -5,
							paddingRight: "0.33rem"
						}}
					/>
					Login with Facebook
				</FbLoginButton>
			) : (
				!hideLogoutState && (
					<FbLoginButton variant="outlined" onClick={logout}>
						Logout
					</FbLoginButton>
				)
			)}
		</>
	);
};

export default FacebookLoginButton;
